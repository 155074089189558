.btn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  cursor: pointer;
  min-width: max-content;
}

a {
  text-decoration: none;
}

.btn-md {
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  min-width: max-content;
}

.white {
  border: 1px solid var(--Grayscale-200, #F2F5F9);
  background: var(--Grayscale-0, #FFF);
  box-shadow: 0 1.5px 4px 0 rgba(10, 9, 11, 0.04);
  color: var(--Grayscale-800, #474F5A);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  text-decoration: none;

  &:hover {
    background: var(--Grayscale-0, #f3f3f3);

  }
}

.blue {
  background: var(--Main-Primary, #0084FF);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.07);
  color: white;

  &:hover {
    background: var(--Main-Primary, #0072de);

  }
}
.green {
  background: var(--Main-Primary, #00c054);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.07);
  color: white;

  &:hover {
    background: var(--Main-Primary, #01a949);

  }
}
.light-blue {
  opacity: 0.4;
  background: var(--Main-0, #F2F9FF);
  color: var(--Main-Primary, #0084FF);
}

.danger {
  background: var(--Main-Primary, #ff6565);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.07);
  color: white;

  &:hover {
    background: var(--Main-Primary, #fd5b5b);

  }
}

.warring {
  background: var(--Main-Primary, #fcd199);
  box-shadow: 0px 1.5px 4px 0px rgba(10, 9, 11, 0.07);
  color: black;

  &:hover {
    background: var(--Main-Primary, #ffcc8b);

  }
}
