.chooseFile {
  background-image: url("./imgs/chooseImg.svg");
  border: transparent;
  display: flex;
  width: 227px;
  height: 156px;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 8px;
}

.file {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.imgLine {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-wrap: wrap;
  width: 100%;
  userSelect: "none";
}
.control {
  clear: both;
}

.time {
  box-shadow: 1px 1px 1px 1px rgba(236, 0, 0, 0.5) !important;
}
.checked {
  box-shadow: 0px 1px 1px 1px rgba(0, 173, 236, 0.5) !important;
}

.choosedLine {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.choosedImg {
  border: transparent;
  background-size: cover;
  display: flex;
  width: 267px;
  height: 196px;
  cursor: grab;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 8px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid white;


  img {
    width: 100%;
  }
}
.mainImg {
  border: 1px solid green;
}
button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dragged {
  boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)";
  button: {
    opacity: 0
  }
}
